<template>
  <div class="main-box">
    <div class="con-box">
      <div class="title">
        <div :class="{ 'tab-active': tabIndex == 1, 'tab': tabIndex != 1 }" @click="onTab(1)">已发布需求</div>
        <div :class="{ 'tab-active': tabIndex == 2, 'tab': tabIndex != 2 }" @click="onTab(2)">产品列表</div>
      </div>
      <div v-if="tabIndex == 1">
        <div class="search-box">
          <a-form :label-col="labelCol" :wrapper-col="wrapperCol">
            <div class="search-con">
              <div class="con-item">
                <a-form-item label="担保方式">
                  <a-input v-model:value="danbaofangshi" placeholder="请输入担保方式"/>
                </a-form-item>
              </div>
              <div class="con-item">
                <a-form-item label="额度" style="">
                  <a-input v-model:value="erdu" placeholder="请输入额度"/>
                </a-form-item>
              </div>
              <div class="con-item">
                <a-form-item label="企业名称">
                  <a-input v-model:value="qiyemingcheng" placeholder="请输入企业名称"/>
                </a-form-item>
              </div>
              <div class="con-itemB">
                <a-button @click="getDemandList">确定筛选</a-button>
              </div>
            </div>
          </a-form>
        </div>
      </div>

      <div v-if="tabIndex == 1">
        <div class="content-box">
          <a-table :pagination='false' :columns="columns" :data-source="dList" v-if="loginType === 'qiye'">
            <template #operation="{record}">
              <a-popconfirm
                  title="确定要删除此条记录吗?"
                  ok-text="是"
                  cancel-text="否"
                  @confirm="onDelete(record)"
                  @cancel="cancel"
              >
                <a-button type="primary">删除</a-button>
              </a-popconfirm>
            </template>
          </a-table>
          <a-table :pagination='false' :columns="columnsA" :data-source="dList" v-else-if="loginType === 'jigou'" @change="handleTableChange">
            <template #enterpriseName="{record}">
              <span style="cursor: pointer;"  @click="onDetails(record)">{{ record.enterpriseName }}</span>
            </template>
            <template #operation="{record}">
              <a-button type="primary" @click="onGive(record)">对接</a-button>
              <a-button type="primary" style="margin-left: 10px" @click="onReport(record)">报告</a-button>
            </template>
          </a-table>
          <a-table :pagination='false' :columns="columnsA" :data-source="dList"
                   v-else-if="loginType === 'zhengfu'"></a-table>
          <div class="page-box">
            <a-pagination :current="page" :total="total1" @change="onPage"/>
          </div>
        </div>
      </div>
      <div v-else>
        <div class="search-box">
          <a-form :label-col="labelCol" :wrapper-col="wrapperCol">
            <div class="search-con">
              <div class="con-item">
                <a-form-item label="服务种类">
                  <a-select v-model:value="serviceCategory" placeholder="请选择服务种类">
                    <a-select-option v-for="(sItem, sii) in serviceList" :key="sii" v-model:value="sItem.BIANMA">
                      {{ sItem.NAME }}
                    </a-select-option>
                  </a-select>
                </a-form-item>
              </div>
              <div class="con-item">
                <a-form-item label="产品名称">
                  <a-input v-model:value="productName" placeholder="请输入产品名称"/>
                </a-form-item>
              </div>
              <!-- <div class="con-item">
                  <a-form-item :wrapperCol="{span:24}">
                      <a-input-search v-model:value="keywords"  placeholder="请输入搜索内容" />
                  </a-form-item>
              </div> -->
              <div class="con-itemB">
                <a-button @click="getProductList">确定筛选</a-button>
              </div>
            </div>
          </a-form>
        </div>
        <div class="content-box">
          <a-table :pagination='false' :columns="columnsB" :data-source="pList" v-if="loginType === 'qiye'">
            <template #operation="{record}" slot-scope="text, record, index">
              <div v-if="pList.length != 0">
                <div>
                  <a-button type="primary" @click="onApply(record)">申请</a-button>
                </div>
              </div>
            </template>
          </a-table>
          <a-table :pagination='false' :columns="columnsC" :data-source="pList"
                   v-else-if="loginType === 'jigou' || loginType === 'zhengfu'"></a-table>
          <div class="page-box">
            <a-pagination :current="page" :total="total1" @change="onPage"/>
          </div>
        </div>
      </div>
    </div>
    <a-modal
        :visible="bankListShow"
        title="选择业务承办机构"
        @cancel="bankListShow = false;searchBankVal = ''"
        @ok="onSubmit"
        ok-text="确定"
        cancel-text="取消"
    >
      <a-input-search v-model:value="searchBankVal" enter-button placeholder="请输入机构名称" @search="getBankList"
                      style="margin-bottom: 20px;"/>
      <div style="height: 200px;overflow-y: scroll;">
        <p v-for="(bItem, bi) in bankLists" :key="bi" @change="onPickBank(bItem)">
          <a-radio-group v-model:value="banliName">
            <a-radio v-model:value="bItem.DEPARTMENT_ID">{{ bItem.NAME }}</a-radio>
          </a-radio-group>
        </p>
      </div>
    </a-modal>
    <a-modal
        :visible="productShow"
        title="对接产品选择"
        :footer="null"
        @cancel="productShow = false"
        width="60%"
    >
      <div class="product-box">
        <ul class="product-con">
          <li v-for="(proItem, proi) in productLists" :key="proi">
            <div class="item-left">
              <img v-if="proItem.LOGO_ADDR != ''" :src="imgApi + proItem.LOGO_ADDR">
              <img v-else src="../assets/images/prodct-con01.png">
            </div>
            <a-divider type="vertical" style="height:auto;margin:0 15px"/>
            <div class="item-right">
              <div class="item-right-top">
                <p>{{ proItem.PRODUCT_NAME }}</p>
                <a-button @click="onAssign(proItem)">指定产品</a-button>
              </div>
              <div class="item-right-bot">
                <p>利率区间：<span class="sp-special">{{ proItem.INTEREST_RATE_START }}</span></p>
                <p>额度范围：<span class="sp-special">{{ proItem.MONEY_START }}万元</span></p>
                <p>发行机构：<span>{{ proItem.BANK_NAME }}</span></p>
                <p>担保方式：<span>{{ proItem.GUARANTEE_METHOD }}</span></p>
                <p>期限范围：<span>{{ proItem.BETWEEN_MONTHS_S }}个月</span></p>
                <p>累计申请：<span>{{ proItem.LINK_COUNT }}次</span></p>
              </div>

            </div>
          </li>
        </ul>
        <div class="page-box">
          <a-pagination show-quick-jumper :current="page" :total="total" @change="onPage"/>
        </div>
      </div>
    </a-modal>
  </div>
</template>

<script>
import {defineComponent} from 'vue'
import {mapGetters} from 'vuex'
import Base from '@/api/base.js'

export default defineComponent({
  computed: {
    ...mapGetters(['orgId', 'isAuth', 'loginType', 'orgName'])
  },
  setup() {
    const columns = [
      {
        title: '序号',
        dataIndex: 'key',
        key: 'key',
        align: 'center',
        width: 60
      },
      {
        title: '企业名称',
        dataIndex: 'enterpriseName',
        key: 'enterpriseName',
        align: 'center'
      },
      {
        title: '服务项',
        dataIndex: 'serviceItem',
        key: 'serviceItem',
        align: 'center'
      },
      {
        title: '担保方式',
        dataIndex: 'assureMeans',
        key: 'assureMeans',
        align: 'center'
      },
      {
        title: '额度',
        dataIndex: 'limitRange',
        key: 'limitRange',
        align: 'center'
      },
      {
        title: '期限范围',
        dataIndex: 'validTerm',
        key: 'validTerm',
        align: 'center'
      },
      {
        title: '发布时间',
        dataIndex: 'time',
        key: 'time',
        width: 120,
        align: 'center'
      },
      {
        title: '操作',
        dataIndex: 'operation',
        align: 'center',
        slots: {
          customRender: 'operation'
        }
      }
    ]
    const columnsA = [
      {
        title: '序号',
        dataIndex: 'key',
        key: 'key',
        align: 'center',
        width: 60
      },
      {
        title: '企业名称',
        dataIndex: 'enterpriseName',
        key: 'enterpriseName',
        align: 'center',
        slots: {
          customRender: 'enterpriseName'
        }
      },
      {
        title: '服务项',
        dataIndex: 'serviceItem',
        key: 'serviceItem',
        align: 'center'
      },
      {
        title: '担保方式',
        dataIndex: 'assureMeans',
        key: 'assureMeans',
        align: 'center'
      },
      {
        title: '额度',
        dataIndex: 'limitRange',
        key: 'limitRange',
        align: 'center'
      },
      {
        title: '期限范围',
        dataIndex: 'validTerm',
        key: 'validTerm',
        align: 'center'
      },
      {
        title: '发布时间',
        sorter: true,
        dataIndex: 'time',
        key: 'time',
        width: 120,
        align: 'center'
      },
      {
        title: '综合评分',
        sorter: true,
        dataIndex: 'score',
        key: 'score',
        align: 'center'
      },
      {
        title: '综合评级',
        sorter: true,
        dataIndex: 'rateResult',
        key: 'rateResult',
        align: 'center'
      },
      {
        title: '信贷额度',
        sorter: true,
        dataIndex: 'finalAmount',
        key: 'finalAmount',
        align: 'center'
      },
      {
        title: '操作',
        dataIndex: 'operation',
        align: 'center',
        slots: {
          customRender: 'operation'
        }
      }
    ]
    // 表格B机构资源
    const columnsB = [
      {
        title: '序号',
        dataIndex: 'key',
        key: 'key',
        align: 'center',
        width: 60
      },
      {
        title: '机构名称',
        dataIndex: 'institutionName',
        key: 'institutionName',
        align: 'center'
      },
      {
        title: '服务项',
        dataIndex: 'serviceItem',
        key: 'serviceItem',
        align: 'center'
      },
      {
        title: '产品名称',
        dataIndex: 'product',
        key: 'product',
        align: 'center'
      },
      {
        title: '担保方式',
        dataIndex: 'assureMeans',
        key: 'assureMeans',
        align: 'center'
      },
      {
        title: '额度范围',
        dataIndex: 'limitRange',
        key: 'limitRange',
        align: 'center'
      },
      {
        title: '期限范围',
        dataIndex: 'betweenMonth',
        key: 'betweenMonth',
        align: 'center'
      },
      {
        title: '时间',
        dataIndex: 'time',
        key: 'time',
        width: 120,
        align: 'center'
      },
      {
        title: '操作',
        dataIndex: 'operation',
        align: 'center',
        slots: {
          customRender: 'operation'
        }
      }
    ]
    const columnsC = [
      {
        title: '序号',
        dataIndex: 'key',
        key: 'key',
        align: 'center',
        width: 60
      },
      {
        title: '机构名称',
        dataIndex: 'institutionName',
        key: 'institutionName',
        align: 'center'
      },
      {
        title: '服务项',
        dataIndex: 'serviceItem',
        key: 'serviceItem',
        align: 'center'
      },
      {
        title: '产品',
        dataIndex: 'product',
        key: 'product',
        align: 'center'
      },
      {
        title: '担保方式',
        dataIndex: 'assureMeans',
        key: 'assureMeans',
        align: 'center'
      },
      {
        title: '额度范围',
        dataIndex: 'limitRange',
        key: 'limitRange',
        align: 'center'
      },
      {
        title: '期限范围',
        dataIndex: 'betweenMonth',
        key: 'betweenMonth',
        align: 'center'
      },
      {
        title: '时间',
        dataIndex: 'time',
        key: 'time',
        width: 120,
        align: 'center'
      }
    ]
    return {

      labelCol: {
        span: 6
      },
      wrapperCol: {
        span: 16
      },
      columns,
      columnsA,
      columnsB,
      columnsC
    }
  },
  data() {
    return {
      danbaofangshi: undefined,
      erdu: undefined,
      qiyemingcheng: undefined,
      tabIndex: 1,
      pList: [],
      dList: [],
      serviceList: [],
      serviceCategory: '',
      productName: '',
      keywords: '',
      bankListShow: false,
      searchBankVal: '',
      bankLists: [],
      itemData: '',
      banliName: '',
      productShow: false,
      imgApi: Base.cs + '/',
      page: 1,
      total1: 0,
      pagination: {},
      productLists: [],
      dItem: '',
      field: '',
      order: ''
    }
  },
  methods: {
    // 删除需求
    onDelete(item) {
      this.$store.dispatch('deleteDemand', {ENTERPRISEDEMAND_ID: item.eid, tm: new Date().getTime()}).then(res => {
        if (res.result === 'success') {
          this.$message.success('删除成功')
          this.getDemandList()
        } else {
          this.$message.error('系统异常，删除失败，请稍后重试！')
        }
      })
    },
    onAssign(item) {
      this.$store.dispatch('applyProduct', {
        FINAPROD_ID: item.FINANCIALPRODUCTS_ID,
        QUOTA_E: this.dItem.limitRange.slice(0, -1),
        BANKCHILD_ID: item.BANK_ID,
        ENTERPRISE_ID: this.dItem.ENTERPRISE_ID,
        ENTEDEMAND_ID: this.dItem.eid,
        LINK_WAY: 'BLANK',
        CONFIRM_YN: 'N',
        tm: new Date().getTime(),
        ENTERPRISE_NAME: this.dItem.enterpriseName,
        BANKCHILD_NAME: this.orgName,
        OVER_DATE: this.OVER_DATE,
        RECEIVE_MONEY: this.RECEIVE_MONEY,
        INTEREST_RATE: this.INTEREST_RATE,
      }).then(res => {
        this.productShow = false
        if (res.result === 'success') {
          this.$message.success('提交成功，请您耐心等待企业确认！')
        } else {
          this.$message.error('系统异常')
        }
      })
    },
    onPage(pageNumber) {
      debugger
      this.page = pageNumber
      if (this.tabIndex == 1) {
        this.getDemandList()
      } else {
        this.getProductList()
      }
      // this.getProductLists()
    },
    onReport(item) {
      this.$router.push({path: '/PersonalLayout/JgDiagnosisHistory', query: {ENTERPRISE_ID: item.ENTERPRISE_ID}})
    },
    onGive(item) {
      this.getProductLists()
      this.productShow = true
      this.dItem = item
    },
    // 详情
    onDetails(item){
      this.$router.push({path:'/PersonalLayout/EnterpriseNameDetails',query: {ENTERPRISE_ID: item.ENTERPRISE_ID,ENTERPRISE_Name:item.enterpriseName,rateResult:item.rateResult,score:item.score,
        comprehensiveQuota:item.limitRange}})
    },
    onApply(item) {
      this.bankLists = []
      console.log(item)
      var name = []
      var id = []
      console.log(this.pList[item.key - 1])
      name = item.HANDINST_BANKNAME.split(',')
      id = item.HANDINST_BANKID.split(',')
      name.map((item, index) => {
        this.bankLists.push({NAME: item, DEPARTMENT_ID: id[index]})
      })
      // this.getBankList()
      this.bankListShow = true
      this.itemData = item
    },
    onPickBank(item) {
      console.log(item)
      this.bankName = item.NAME
      this.DEPARTMENT_ID = item.DEPARTMENT_ID
      for (let i in this.bankLists) {
        if (item.DEPARTMENT_ID == this.bankLists[i].DEPARTMENT_ID) {
          this.bankLists[i].selected = true
        } else {
          this.bankLists[i].selected = false
        }
      }
    },
    onTab(i) {
      this.tabIndex = i
      if (i == 1) {
        this.getDemandList()
      } else {
        this.getProductList()
      }
    },
    getProductLists() {
      this.productLists = []
      this.$store.dispatch('getProductList', {
        showCount: -1,
        currentPage: this.page,
        BANK_ID: this.orgId
      }).then(res => {
        if (res.result === 'success') {
          let datas = res.varList
          if (datas.length != 0) {
            for (let i in res.varList) {
              if (res.varList[i].LOGO_ADDR) {
                if (res.varList[i].LOGO_ADDR.split('.')[1] != 'jpg'
                    && res.varList[i].LOGO_ADDR.split('.')[1] != 'png'
                    && res.varList[i].LOGO_ADDR.split('.')[1] != 'jpeg') {
                  res.varList[i].LOGO_ADDR = ''
                }
              } else {
                res.varList[i].LOGO_ADDR = ''
              }
            }
            this.productLists = res.varList
            this.total = res.page.totalResult
          } else {
            this.productLists = []
            this.total = 0
          }
        } else {
          this.productLists = []
          this.total = 0
          this.$message.error('服务器异常，获取金融产品列表异常！')
        }
      })
    },
    // 获取金融产品列表
    getProductList() {
      debugger
      this.pList = []
      let params = {}
      if (this.loginType === 'qiye' || this.loginType === 'zhengfu') {
        params = {
          showCount: -1,
          currentPage: this.page,
          SERVICE_COLUMN: this.serviceCategory,
          PRODUCT_NAME: this.productName
        }
      } else if (this.loginType === 'jigou') {
        params = {
          showCount: -1,
          currentPage: this.page,
          BANK_ID: this.orgId,
          SERVICE_COLUMN: this.serviceCategory,
          PRODUCT_NAME: this.productName,
          BETWEEN_MONTHS_S: '',
          GUARANTEE_METHOD: '',
          MONEY_START: '',
        }
      }
      this.$store.dispatch('getProductList', params).then(res => {
        if (res.result === 'success') {
          let datas = res.varList
          this.total1 = res.page.totalResult
          if (datas.length != 0) {
            for (let i in datas) {
              this.pList.push({
                key: parseInt(i) + 1,
                pid: datas[i].FINANCIALPRODUCTS_ID,
                institutionName: this.loginType === 'qiye' ? datas[i].BANKNAME : this.loginType === 'jigou' ? this.orgName : datas[i].BANKNAME,
                serviceItem: datas[i].SERVICE_COLUMN,
                product: datas[i].PRODUCT_NAME,
                HANDINST_BANKNAME: datas[i].HANDINST_BANKNAME,
                HANDINST_BANKID: datas[i].HANDINST_BANKID,
                assureMeans: datas[i].GUARANTEE_METHOD,
                limitRange: datas[i].MONEY_START + '万以下',
                betweenMonth: datas[i].BETWEEN_MONTHS_S + '个月以下',
                time: datas[i].RELEAS_TIME.split(' ')[0]
              })
            }
          } else {
            this.pList = []
          }
        } else {
          this.pList = []
          this.$message.error('服务器异常，获取金融产品列表异常！')
        }
      })
    },
    // 申请金融产品
    onSubmit() {
      console.log(this.productDetail)
      this.$store.dispatch('applyProduct', {
        FINAPROD_ID: this.itemData.pid,
        QUOTA_E: this.itemData.limitRange.split('万以下')[0],
        BANKCHILD_ID: this.DEPARTMENT_ID,
        ENTERPRISE_ID: this.orgId,
        ENTEDEMAND_ID: '',
        LINK_WAY: 'OWN',
        CONFIRM_YN: 'Y',
        tm: new Date().getTime(),
        ENTERPRISE_NAME: this.orgName,
        BANKCHILD_NAME: this.bankName,
      }).then(res => {
        this.bankListShow = false
        this.searchBankVal = ''
        if (res.result === 'success') {
          this.$message.success('申请成功，请您耐心等待审核！')
        } else {
          this.$message.error('系统异常')
        }
      })
    },

    // 获取需求列表
    getDemandList() {
      this.dList = []
      let params = {}
      if (this.loginType === 'jigou' || this.loginType === 'zhengfu') {
        params = {
          showCount: 10,
          currentPage: this.page,
          ENTERPRISE_NAME: this.qiyemingcheng,
          QUOTA_E: this.erdu,
          ENTERPRISE_ID: '',
          GUARANTEE_METHOD: this.danbaofangshi,
          field:this.field,
          order:this.order,
          XCX_NAME:localStorage.getItem('xcxName')
        }
      } else if (this.loginType === 'qiye') {
        params = {
          showCount: 10,
          currentPage: this.page,
          ENTERPRISE_ID: this.orgId,
          ENTERPRISE_NAME: this.qiyemingcheng,
          QUOTA_E: this.erdu,
          GUARANTEE_METHOD: this.danbaofangshi,
          XCX_NAME:localStorage.getItem('xcxName')
        }
      }
      this.$store.dispatch('GetListLimit', {...params}).then(res => {
        if (res.result === 'success') {
          let datas = res.varList
          this.total1 = res.page.totalResult
          console.log(this.total, 'total')
          if (datas.length != 0) {
            for (let i in datas) {
              this.dList.push({
                key: parseInt(i) + 1,
                ENTERPRISE_ID: datas[i].ENTERPRISE_ID,
                eid: datas[i].ENTERPRISEDEMAND_ID,
                enterpriseName: datas[i].ENTERPRISE_NAME,
                serviceItem: datas[i].SERVICE_ITEM,
                assureMeans: datas[i].GUARANTEE_METHOD,
                limitRange: datas[i].QUOTA_E + '万',
                validTerm: datas[i].VALID_TERM + '个月以下',
                time: datas[i].ISSUED_DATE,
                score: datas[i].SCORE,
                rateResult: datas[i].RATE_RESULT,
                finalAmount: datas[i].FINAL_AMOUNT == null ? '':datas[i].FINAL_AMOUNT+ '万',
              })
            }
          } else {
            this.dList = []
          }
        } else {
          this.dList = []
          this.$message.error('服务器异常，获取金融产品列表异常！')
        }
      })
    },
    // 获取服务项下拉选项列表
    getSelectList() {
      this.$store.dispatch('getServiceItemList', {
        DICTIONARIES_ID: '5e3766b8ee284c929c72cbfeba325b3b',
        tm: new Date().getTime()
      }).then(res => {
        if (res.result === 'success') {
          this.serviceList = res.list
        } else {
          this.serviceList = []
        }
      })
    },
    // 获取银行列表
    getBankList() {
      this.$store.dispatch('getDepBankList', {
        showCount: -1,
        currentPage: 1,
        BANKNAME: this.searchBankVal
      }).then(res => {
        if (res.result === 'success') {
          for (let i in res.varList) {
            res.varList[i].selected = false
          }
          this.bankLists = res.varList
        } else {
          this.bankLists = []
        }
      })
    },
    handleTableChange(pag, filters, sorter){
      console.log("pag",pag);
      console.log("filters",filters);
      console.log("sorter",sorter);
      this.field = sorter === null || sorter === undefined?'':sorter.field;
      this.order = sorter === null || sorter === undefined?'':sorter.order;
      this.getDemandList();
    }
  },
  created() {
    if (this.loginType === 'qiye' && this.isAuth === 'N') {
      this.$message.info('请先完成企业认证或个体认证后才能进入')
      this.$router.push('/PersonalLayout/WareHouse')
      return false
    }
    // 获取需求列表
    this.getDemandList()
    // 获取下拉选项列表
    this.getSelectList()
  }
})
</script>
<style lang="less" scoped>
.content-box ::v-deep(.ant-table-thead) {
  background: @color-blue; /* 标准语法 */
}

.content-box ::v-deep(.ant-table-thead th) {
  background-color: transparent;
  color: @color-ff;
}

</style>
<style lang="less" scoped>
.main-box {
  width: @main-width-base;
  margin: 20px auto;

  .con-box {
    padding: 15px;
    background-color: @color-ff;
    box-shadow: 0px 0px 10px #929292;
    border-radius: 5px;
    margin: 20px 0;
    position: relative;

    .title {
      font-size: @font-lg;
      padding: 0px 10px;
      padding-bottom: 15px;
      border-bottom: @border-base;
      display: flex;
      align-items: center;

      .tab {
        padding: 2px 15px;
        color: #A7A7A7;
        cursor: pointer;
      }

      .tab-active {
        padding: 2px 15px;
        color: #ffffff;
        cursor: pointer;
        background: @color-blue; /* 标准语法 */
      }
    }

    .search-box {
      .search-con {
        padding-top: 15px;
        display: flex;
        justify-content: space-between;
        flex-wrap: wrap;

        .con-item {
          width: 25%;
        }

        .con-itemC {
          display: flex;
          justify-content: space-between;

          .ant-form-item {
            width: 70%;
          }

          .con-itemB {
            width: 30%;
          }
        }

        .con-itemA {
          width: 25%;
        }

        .con-itemB {
          height: 40px;
          line-height: 40px;
          text-align: center;
          width: 15%;

          ::v-deep(.ant-btn) {
            padding: 0 32px;
            color: #ffffff;
            background: @color-blue; /* 标准语法 */
          }
        }
      }
    }

    .content-box {
      font-size: 16px;
    }
  }

  .con-box::before {
    content: '';
    position: absolute;
    left: -3px;
    top: 10px;
    width: 6px;
    height: 40px;
    background: @color-blue; /* 标准语法 */
  }
}

.product-box {
  .product-con {
    padding: 20px;
    background-color: @color-ff;
    border-radius: 5px;

    li {
      display: flex;
      justify-content: space-between;
      padding: 20px 0;
      border-bottom: @border-base;

      .item-left {
        width: 384px;

        img {
          width: 100%;
          height: 215px;
        }
      }

      .item-right {
        width: 880px;

        .item-right-top {
          display: flex;
          justify-content: space-between;
          align-items: center;
          padding-bottom: 10px;

          p {
            display: flex;
            justify-content: space-between;
            align-items: center;
            margin-bottom: 0;
            font-size: 48px;
            font-weight: bold;

            img {
              margin-left: 20px;
              width: 150px;
              height: 40px;
            }
          }
        }

        .item-right-bot {
          display: flex;
          justify-content: start;
          flex-wrap: wrap;
          border-top: @border-ea;
          padding-top: 10px;

          p {
            width: 290px;
            padding: 5px;
            margin-bottom: 0;
            font-size: @font-lg;
            line-height: 30px;

            .sp-special {
              color: @color-blue;
              font-weight: bold;
            }
          }
        }
      }
    }

    li:first-child {
      padding-top: 0;
    }
  }
}

.page-box {
  padding: 15px 20px;
  background-color: @color-ff;
  border-radius: 5px;
  position: relative;

  .ant-pagination {
    display: flex !important;
    justify-content: flex-end !important;
    align-items: center;
  }
}

.con-item1 {
  margin: 25px 20px 0px 0px;

}
</style>
